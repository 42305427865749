<script setup lang="ts">
import type { AnimationItem } from 'lottie-web'
// inspired https://github.com/badsaarow/lottie-vue3/blob/main/src/components/Lottie.vue
// @ts-ignore
import lottie from 'lottie-web/build/player/lottie_light.min.js'

const {
  autoplay = true,
  data,
  loop = true,
  src,
} = defineProps<{
  loop?: boolean
  autoplay?: boolean
  /**
   * The path to the .json animation file
   */
  src?: string
  data?: Record<string, unknown>
}>()

const target = ref<HTMLElement | null>(null)
const animationItem = shallowRef<AnimationItem>()

function loadAnimation() {
  if (!target.value) return
  console.log('loadAnimation start')
  animationItem.value = lottie.loadAnimation({
    container: target.value,
    renderer: 'svg',
    loop: loop,
    autoplay: autoplay,
    ...(src
      ? { path: src }
      : {
          animationData: data,
        }),
  })
}

onMounted(() => {
  // fix: https://nuxt.com/docs/guide/directory-structure/components#client-components
  nextTick().then(() => {
    loadAnimation()
  })
})

onBeforeUnmount(() => {
  if (animationItem.value) {
    animationItem.value.destroy()
  }
})
</script>

<template>
  <div ref="target" />
</template>
